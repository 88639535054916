import { filter, find, map, remove } from "lodash"
import * as React from "react"
import { CartItem, ConsumableCartItem } from "./UseCartTypes"
import { createContext, useContext } from "react"

type ContextType = {
  cart: ConsumableCartItem[]
  handleAdd: (item: ConsumableCartItem) => void
  handleRemove: (item: ConsumableCartItem) => void
  handleQuantityChange: (item: ConsumableCartItem) => void
}
export const ConsumableCartContext = createContext<ContextType | undefined>(
  undefined
)

export const useConsumableCart = (): ContextType => {
  const context = useContext(ConsumableCartContext)
  if (context === undefined) {
    throw new Error("context must be defined")
  }
  return context
}

export const ConsumableCartProvider = ({ children }) => {
  const windowDefined = typeof window !== "undefined"

  const windowGlobal = windowDefined && window

  const prevCart: ConsumableCartItem[] = windowDefined
    ? JSON.parse(windowGlobal.localStorage.getItem("consumableCart")) ?? []
    : []

  const [cart, setCart] = React.useState(prevCart)

  const handleAdd = (item: ConsumableCartItem) => {
    if (windowDefined) {
      const newItem = {
        item: item.item,
        quantity: getQuantity(item),
        consumableId: item.consumableId,
        consumable: item.consumable,
      }

      const removedCart = filter(cart, cartItem => cartItem.item != item.item)

      const newCart = [...removedCart, newItem]
      setCart(newCart)
      windowGlobal.localStorage.setItem(
        "consumableCart",
        JSON.stringify(newCart)
      )
    }
  }

  const handleRemove = (item: ConsumableCartItem) => {
    if (windowDefined) {
      const newCart = filter(cart, cartItem => cartItem.item != item.item)
      windowGlobal.localStorage.setItem(
        "consumableCart",
        JSON.stringify(newCart)
      )
      setCart(newCart)
    }
  }

  const handleQuantityChange = (item: ConsumableCartItem) => {
    if (windowDefined) {
      if (item.quantity === 0) {
        handleRemove(item)
        return null
      }
      const newCart = map(cart, cartItem => {
        const quantity =
          cartItem.item == item.item ? item.quantity : cartItem.quantity
        return {
          item: cartItem.item,
          quantity: quantity,
          consumableId: item.consumableId,
        }
      })

      windowGlobal.localStorage.setItem(
        "consumableCart",
        JSON.stringify(newCart)
      )
      setCart(newCart)
    }
  }

  const getQuantity = (item: ConsumableCartItem) => {
    if (windowDefined) {
      return (
        (find(cart, cartItem => cartItem.item == item.item)?.quantity ?? 0) + 1
      )
    }
  }

  const CartServiceValue = React.useMemo(
    () => ({
      cart,
      handleAdd,
      handleRemove,
      handleQuantityChange,
    }),
    [cart]
  )
  return (
    <ConsumableCartContext.Provider value={CartServiceValue}>
      {children}
    </ConsumableCartContext.Provider>
  )
}
