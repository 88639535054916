module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{},"allPageHeaders":["X-Frame-Options: SAMEORIGIN"],"mergeSecurityHeaders":false,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BMC Equipment & Tools","short_name":"BMC","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/bmc-equip-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"565c90d30135ee632052e9e0e718b952"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
