import { filter, find, map, remove } from "lodash"
import * as React from "react"
import { CartItem } from "./UseCartTypes"
import { createContext, useContext } from "react"

type ContextType = {
  cart: CartItem[]
  handleAdd: (item: CartItem) => void
  handleRemove: (item: CartItem) => void
  handleQuantityChange: (item: CartItem) => void
}
export const CartContext = createContext<ContextType | undefined>(undefined)

export const useCart = (): ContextType => {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error("context must be defined")
  }
  return context
}

export const CartProvider = ({ children }) => {
  const windowDefined = typeof window !== "undefined"

  const windowGlobal = windowDefined && window

  const prevCart: CartItem[] = windowDefined
    ? JSON.parse(windowGlobal.localStorage.getItem("cart")) ?? []
    : []

  const [cart, setCart] = React.useState(prevCart)

  const handleAdd = (item: CartItem) => {
    if (windowDefined) {
      const newItem = {
        item: item.item,
        modelDetails: item.modelDetails,
        quantity: getQuantity(item),
      }

      const removedCart = filter(
        cart,
        cartItem => cartItem.item.id != item.item.id
      )

      const newCart = [...removedCart, newItem]
      setCart(newCart)
      windowGlobal.localStorage.setItem("cart", JSON.stringify(newCart))
    }
  }

  const handleRemove = (item: CartItem) => {
    if (windowDefined) {
      const newCart = filter(cart, cartItem => cartItem.item.id != item.item.id)
      windowGlobal.localStorage.setItem("cart", JSON.stringify(newCart))
      setCart(newCart)
    }
  }

  const handleQuantityChange = (item: CartItem) => {
    if (windowDefined) {
      if (item.quantity === 0) {
        handleRemove(item)
        return null
      }
      const newCart = map(cart, cartItem => {
        const quantity =
          cartItem.item.id == item.item.id ? item.quantity : cartItem.quantity
        return {
          item: cartItem.item,
          modelDetails: cartItem.modelDetails,
          quantity: quantity,
        }
      })

      windowGlobal.localStorage.setItem("cart", JSON.stringify(newCart))
      setCart(newCart)
    }
  }

  const getQuantity = (item: CartItem) => {
    if (windowDefined) {
      return (
        (find(cart, cartItem => cartItem.item.id == item.item.id)?.quantity ??
          0) + 1
      )
    }
  }

  const CartServiceValue = React.useMemo(
    () => ({
      cart,
      handleAdd,
      handleRemove,
      handleQuantityChange,
    }),
    [cart]
  )
  return (
    <CartContext.Provider value={CartServiceValue}>
      {children}
    </CartContext.Provider>
  )
}
