// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-consumable-page-tsx": () => import("./../../../src/components/ConsumablePage.tsx" /* webpackChunkName: "component---src-components-consumable-page-tsx" */),
  "component---src-components-machine-page-tsx": () => import("./../../../src/components/MachinePage.tsx" /* webpackChunkName: "component---src-components-machine-page-tsx" */),
  "component---src-components-product-list-page-tsx": () => import("./../../../src/components/ProductListPage.tsx" /* webpackChunkName: "component---src-components-product-list-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-catalog-cat-tsx": () => import("./../../../src/pages/catalog/cat.tsx" /* webpackChunkName: "component---src-pages-catalog-cat-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-consumables-tsx": () => import("./../../../src/pages/consumables.tsx" /* webpackChunkName: "component---src-pages-consumables-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

